import backimage from "../resources/prdImgs/gradientbg.jpg"


function DesignStandard() {
  const backgroundStyle : React.CSSProperties= {
    backgroundImage: `url(${backimage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  
   
  };
  return (

    
    <div className="bg-transparent pt-12 pb-44 " style={backgroundStyle}>
      <div className="mt-28 container mx-auto px-4" >
        <h2 className="text-4xl font-extrabold text-black dark:text-yellow-300 sm:text-3xl">
          DESIGN STANDARD
        </h2>
        <h2 className="text-2xl font-extrabold text-yellow-300 sm:text-xl">
          British / European Standards
        </h2>
        <p className="text-md mt-8 text-zinc-900 ">
          1.The British Standard Specification for Design and Construction of
          vessels and tanks in reinforced plastics includes 1987 with an
          addendum of 1990.
        </p>
        <p className="text-md mt-8 text-zinc-900 ">
          2.The British Standard Specification for Reinforced Plastics Pipes,
          Fittings, and Joints for Process Plants was 1984.
        </p>
        <p className="text-md mt-8 text-zinc-900 ">
          3.The British Standard Code of Practice for Design and Construction of
          Glass Reinforced Plastics (GRP) Piping Systems for Individual Plants
          or Sites was published in 1989.
        </p>
        <p className="text-md mt-8 text-zinc-900 ">
          4.The British Standard for Lining of Equipment with Polymeric Material
          for the Process Industries is 2012.
        </p>
        <p className="text-md mt-8 text-zinc-900 ">
          5.In 1997, we developed underground tanks made of Glass Reinforced
          Plastics (GRP). These tanks are horizontal cylindrical and are used
          for the non-pressure storage of liquid petroleum-based fuels.
        </p>
      </div>
      </div>
    
  );
}

export default DesignStandard;
