import React from "react";
import { useEffect } from 'react';
import "./App.css";
import "./customCss/animations.css";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/footer/footer";
import History from "./pages/History";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Contact from "./pages/Contact";
import CertiAndCLients from "./pages/CertiAndCLients";
import NotFound from "./pages/NotFound";
import WhyUs from "./pages/WhyUs";
import CompanyProfile from "./pages/CompanyProfile";
import DesignStandard from "./pages/DesignStandard";
import  SingleProduct from "./pages/SingleProduct";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ScrollToTop } from "./components/ScrollToTop";

function App() {
  // @ts-ignore
  let aniLeft,aniRight,aniMid;
  const observerLeft = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('fade-in-right');
        return;
      }
  
      entry.target.classList.remove('fade-in-right');

    });
  });
  const observerRight = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('fade-in-left');
        return;
      }
  
      entry.target.classList.remove('fade-in-left');

    });
  });
  const observerMid = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('text-focus-in');
        return;
      }
  
      entry.target.classList.remove('text-focus-in');

    });
  });
  
  useEffect(() => {
    aniLeft = document.querySelectorAll('.ani-left');
    aniRight = document.querySelectorAll('.ani-right');
    aniMid = document.querySelectorAll('.ani-mid');
    
    aniLeft.forEach((element) => {observerLeft.observe(element)});
    aniRight.forEach((element) => {observerRight.observe(element)});
    aniMid.forEach((element) => {observerMid.observe(element)});
    
    return () => {
      //@ts-ignore
      aniLeft.forEach((element) => {observerLeft.unobserve(element)});
      //@ts-ignore
      aniRight.forEach((element) => {observerRight.unobserve(element)});
      //@ts-ignore
      aniMid.forEach((element) => {observerRight.unobserve(element)});
    }
  }, [])

  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <div className='relative'>
          <Navbar />
        </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Products" element={<Products />} />
          <Route path='/SingleProduct' element={<SingleProduct />}>
              <Route path=':productId' element={<SingleProduct />}/>
          </Route>
          <Route path="/History" element={<History />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/CertiAndCLients" element={<CertiAndCLients />} />
          <Route path="/WhyUs" element={<WhyUs />} />
          <Route path="/CompanyProfile" element={<CompanyProfile />} />
          <Route path="/DesignStandard" element={<DesignStandard />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
