import React,{useContext} from 'react'
import { useParams } from 'react-router-dom';
import {ShopContext } from '../context/ShopContext'
import ProductDisplay from '../components/ProductDisplay/ProductDisplay'

interface Product {
   // productId: string;
    id: number;
    name: string;
    img: string;
    description?:string;
    allimg:Array<string>;
    price: number;
    d1:string;
    d2:Array<string>;
    d3:Array<string>;
    d4:Array<string>;
    d5:Array<string>;
    d6:Array<string>;
}

function SingleProduct() {
    const {all_product} = useContext(ShopContext)as  { all_product: Product[] } ;
    const {productId} = useParams();
    const product= all_product.find((e:Product)=>e.id===Number(productId))
    if (!product) {
        return <div>Product not found</div>;
      }
    return (
       <div >
         <ProductDisplay product={product}/>
      </div>
    )
}

export default SingleProduct;
