import { Canvas } from "@react-three/fiber";
import HeroModel from "./HeroModel";

function ModelLoader() {
    

  return (
    <>
      <Canvas
        camera={{ position: [-17.5, 3, 10.5], fov: 65, near: 0.1, far: 1000 }}
        shadows
      >
        <HeroModel />
      </Canvas>
    </>
  );
}

export default ModelLoader;
