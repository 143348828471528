import cert1 from "../resources/images/cert1.jpg";
import cert2 from "../resources/images/cert2.jpg";
import cert3 from "../resources/images/cert3.jpg";
import Clients from "../components/Clients";

function CertiAndCLients() {
  return (
    <>
      <div className="container mx-auto px-4 my-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-yellow-300 sm:text-3xl">
              Certificates
            </h2>
            <p className="mt-6 text-base text-gray-300">
              We have an in-house Engineering & Drawing Department with
              compliance of BS, ASTM, ASME, NBC, UBC, DIN, and D/S standards.
              Our team of techno-commercial experts understands the clients
              detail requirements and delivers the product to meet their
              requirements from last 25 Years 41/2 decades and growing stronger
              day by day.
            </p>
          </div>
          <div className="my-10 mx-auto flex flex-col lg:flex-row lg:justify-evenly">
            <div className="img-block mb-5 lg:w-1/4">
              <span></span>
              <img src={cert1} />
            </div>
            <div className="img-block mb-5 lg:w-1/4">
              <span></span>
              <img src={cert2} />
            </div>
            <div className="img-block mb-5 lg:w-1/4">
              <span></span>
              <img src={cert3} />
            </div>
          </div>
        </div>
      {/* <Clients/> */}
      </div>
    </>
  );
}

export default CertiAndCLients;
