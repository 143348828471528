import React, { useState } from "react";
import { Link } from "react-router-dom";

interface Product {
  name: string;
  img: string;
  price: number;
  allimg: Array<string>;
  description1?: string;
  description2?: string;
  d1: string;
  d2: Array<string>;
  d3: Array<string>;
  d4: Array<string>;
  d5: Array<string>;
  d6: Array<string>;
}

const ProductDisplay = (props: { product: Product }) => {
  const { product } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  //@ts-ignore
  const handleImageChange = (index: Number) => {
    setActiveIndex(activeIndex + 1);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? product.allimg.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === product.allimg.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="rounded-lg">
      <div className="relative rounded-lg flex flex-col align-middle justify-center overflow bg-transparent py-6">
        <div className="mt-16 ml-2 lg:mt-20">
          <Link
            to="/Products"
            className="flex w-full text-yellow-300 px-3 py-2 rounded-md"
          >
            <svg width="25px" height="25px" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="#fde047" d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z"/><path fill="#fde047" d="m237.248 512 265.408 265.344a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312L237.248 512z"/></svg>   All Products
          </Link>
        </div>
        <div className="mt-3 rounded-lg w-full items-center mx-auto max-w-screen-lg">
          <div className="bg-gray-900 border-2 rounded-lg border-gray-800/50 group grid w-full lg:DisplayHeight lg:max-2xl:grid-rows-1 lg:max-2xl:grid-cols-2 2xl:grid-cols-2">
            <div className="relative flex items-center flex-col overflow-hidden">
              <div
                // key={index}
                className={`duration-500 ease-in-out`}
                // data-carousel-item
              >
                <img
                  loading="lazy"
                  src={product.allimg[activeIndex]}
                  className="w-full lg:w-full h-auto mx-auto"
                  alt={`Image ${activeIndex}`}
                />
              </div>

              <button
                onClick={handlePrev}
                type="button"
                className=" absolute bottom-0
                start-0 z-5 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                data-carousel-prev
              >
                <svg
                  className="  w-4 h-4 text-white lg:hover:text-yellow-400 rtl:rotate-180"
                  aria-hidden="true"
                  // xmlns={tank}
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 1 1 5l4 4"
                  />
                </svg>
                <span className="sr-only">Previous</span>
              </button>
              <button
                onClick={handleNext}
                type="button"
                className=" absolute bottom-0 end-0 z-5 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none"
                data-carousel-next
              >
                <svg
                  className=" w-4 h-4 text-white lg:hover:text-yellow-400 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
                <span className="sr-only">Next</span>
              </button>
            </div>
            <div>
              <div className=" border-t-2 sm:border-l-2 border-gray-800/50 p-4 DisplayHeight lg:overflow-y-scroll" >
                <h1 className="text-3xl mb-3 text-yellow-300">{product.name}</h1>
                <p className="text-base peer mb-3 text-gray-400">
                  {product.d1}
                </p>
                <h3 className="mb-2 font-semibold text-xl mt-8 text-yellow-300">
                  FEATURES
                </h3>
                <ul
                  role="list"
                  className="marker:text-sky-400 list-disc pl-5 space-y-1 text-slate-500"
                >
                  {product.d2.map((feat: string) => (
                    <li>{feat}</li>
                  ))}
                </ul>
                <h3 className="mb-2 font-semibold text-xl mt-8 text-yellow-300">
                  APPLICATION
                </h3>
                <ul
                  role="list"
                  className="marker:text-sky-400 list-disc pl-5 space-y-1 text-slate-500"
                >
                  {product.d3.map((feat: string) => (
                    <li>{feat}</li>
                  ))}
                </ul>
                <h3 className="mb-2 font-semibold text-xl mt-8 text-yellow-300">
                TECHNICAL SPECIFICATION
                </h3>
                <ul
                  role="list"
                  className="marker:text-sky-400 list-disc pl-5 space-y-1 text-slate-500"
                >
                  {product.d4.map((feat: string) => (
                    <li>{feat}</li>
                  ))}
                </ul>
                <h3 className="mb-2 font-semibold text-xl mt-8 text-yellow-300">
                MATERIAL OF CONSTRUCTION
                </h3>
                <ul
                  role="list"
                  className="marker:text-sky-400 list-disc pl-5 space-y-1 text-slate-500"
                >
                  {product.d5.map((feat: string) => (
                    <li>{feat}</li>
                  ))}
                </ul>
                <h3 className="mb-2 font-semibold text-xl mt-8 text-yellow-300">
                QUALITY SPECIFICATIONS
                </h3>
                <ul
                  role="list"
                  className="marker:text-sky-400 list-disc pl-5 space-y-1 text-slate-500"
                >
                  {product.d6.map((feat: string) => (
                    <li>{feat}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDisplay;
