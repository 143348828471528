import { useRef } from "react";
import { Link } from "react-router-dom";
import Atropos from "atropos/react";
import './items.css'
import "atropos/css";

interface Product {
  id: number;
  name: string;
  img: string;
  description?: string;
  description1?:string;
  d1:string;
  allimg?: Array<string>;
  price?: number;
}
const Items = (props: Product) => {
  const myBox = useRef(null);

  return (
    <>
      {
          <div className="rounded-lg shadow bg-gray-800 border-gray-700 mt-3" >
            <Link
              ref={myBox}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={`/SingleProduct/${props.id}`}
              className="block relative"
            >
              <div className="ItemBg" data-atropos-offset="-3">
                <img
    
                  className="rounded-t-lg"
                  src={props.img}
                  alt="product image"
                  data-atropos-offset="10"
                />
              </div>
              <div className="px-5 py-5">
                <a href="#">
                  <h5
                    className="text-yellow-400 text-xl font-semibold tracking-tight "
                    data-atropos-offset="3"
                  >
                    {props.name}
                  </h5>
                </a>
                <div className="descrip flex items-center mt-2.5 mb-2 text-gray-300">
                  {props.d1}
                </div>
                <div className="flex items-center justify-between">
                  <button
                    type="button"
                    className=" border focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-3 py-2 text-center me-2 mb-2 border-yellow-300 text-yellow-300 hover:text-white  focus:ring-yellow-900"
                  >
                    <b data-atropos-offset="5">More Info...</b>
                  </button>
                </div>
              </div>
            </Link>
          </div>
      }
    </>
  );
};

export default Items;
