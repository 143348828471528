import React, { useContext } from "react";
import { ShopContext } from "../context/ShopContext";
import Item from "../components/Items/items";
import Atropos from "atropos/react";

interface Product {
  id: number;
  name: string;
  img: string;
  d1: string;
  price?: number;
}

const Products: React.FC = () => {
  const { all_product } = useContext(ShopContext) as { all_product: Product[] };

  return (
    <>
      
      <div className="bg-transparent relative ">
        <div className="mx-auto max-w-2xl px-14 py-16 sm:px-20 sm:py-24 lg:max-w-7xl lg:px-20">
          {/* <h2 className="text-5xl logo-font text-yellow-400">Our Products</h2> */}
          <div className=" flex flex-col items-center sm:grid gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 mt-2">
          {all_product.map((item) => {
              if (window.screen.width > 650)
                return (
                  <Atropos className="my-atropos rounded-lg mt-7">
                    <Item
                      key={item.id}
                      id={item.id}
                      name={item.name}
                      img={item.img}
                      d1={item.d1}
                    />
                  </Atropos>
                );
              else
                return (
                  <Item
                    key={item.id}
                    id={item.id}
                    name={item.name}
                    img={item.img}
                    d1={item.d1}
                  />
                );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
