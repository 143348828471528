import { Suspense, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import { OrbitControls, Stars, Stats } from "@react-three/drei";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
//import GrateModel from "./GrateModel";
// import modelLocation from "../heroModel/IndustrialSilo_6_fbx.gltf";


function HeroModel({ ...props }) {
  // useEffect(() => {
  //   document.addEvent

  //   return () => {
  //     second
  //   }
  // }, [])

  const heroModelRef = useRef(null);

  const mainGltf = useLoader(
    GLTFLoader,
    "/heroModel/IndustrialSilo_6_fbx.gltf"
  );

  useFrame(({ clock }) => {
    // @ts-ignore
    if (heroModelRef.current) heroModelRef.current.rotation.y += 0.0002;
  });

  return (
    <>
      <ambientLight intensity={0.5} />
      <directionalLight
        position={[-66, 10, -88]}
        castShadow
        intensity={Math.PI * 1.2}
      />
      <directionalLight
        position={[33, 10, 44]}
        castShadow
        intensity={Math.PI * 0.5}
      />
      <Suspense>
        <group
          ref={heroModelRef}
          dispose={null}
        >
          <primitive
            object={mainGltf.scene}
            scale={window.screen.width > 750 ? 1.5 : 1}
            position={[0, -2, 0]}
            children-0-castShadow
          />
        </group>
      </Suspense>

      <OrbitControls enablePan={false} enableZoom={false} minPolarAngle={Math.PI * 4/10} maxPolarAngle={Math.PI * 4/10}/>
    </>
  );
}

export default HeroModel;
