import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';

function Contact() {

  const form = useRef();

  const sendEmail = (e:Event) => {
    e.preventDefault();

    // @ts-ignore
    emailjs.sendForm('service_5ixjh6n', 'template_new', form.current, {
        publicKey: 'eGL0wgiGSjGQPxdaN',
      })
      .then(
        () => {
          toast('Email sent succesfully!');
        },
        (error) => {
          toast('failed to send email...');
        },
      );
  };

  return (
    <section className="bg-transparent py-10" id="contact">
      <Toaster />
      <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:py-20">
        <div className="mb-4">
          <div className="mb-6 max-w-3xl text-center sm:text-center md:mx-auto md:mb-12">
            {/* <p className="text-base font-semibold uppercase tracking-wide text-blue-600 dark:text-blue-200">
              Contact
            </p> */}
            <h2 className="font-heading mb-4 font-bold tracking-tight text-gray-900 dark:text-white text-3xl sm:text-5xl beena">
              Get in Touch
            </h2>
            {/* <p className="mx-auto mt-4 max-w-3xl text-xl text-gray-600 dark:text-slate-400">
              In hac habitasse platea dictumst
            </p> */}
          </div>
        </div>
        <div className="flex items-stretch justify-center">
          <div className="grid md:grid-cols-2">
            <div className="h-full p-6">
              <p className="mt-3 mb-12 text-gray-600 dark:text-slate-400 text-base font-mono">
                For any kind of query, contact us with the details below.
              </p>
              <ul className="mb-6 md:mb-0">
                <li className="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="yellow"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="h-6 w-6"
                    >
                      <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                      <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
                    </svg>
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-1 text-lg font-medium leading-6 text-gray-900 dark:text-white beena">
                      Our Address
                    </h3>
                    <p className="text-gray-600 dark:text-slate-400 text-sm font-mono">
                      Neelkanth industries Plot no 13,
                    </p>
                    <p className="text-gray-600 dark:text-slate-400 text-sm font-mono">
                      Village Dhamni,Taluka: khalapur,Dist: Raigad
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="yellow"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="h-6 w-6"
                    >
                      <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                      <path d="M15 7a2 2 0 0 1 2 2"></path>
                      <path d="M15 3a6 6 0 0 1 6 6"></path>
                    </svg>
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-1 text-lg font-medium leading-6 text-gray-900 dark:text-white beena">
                      Contact
                    </h3>
                    <p className="text-gray-600 dark:text-slate-400 text-sm font-mono">
                      Mobile: 8999019404
                    </p>
                    <p className="text-gray-600 dark:text-slate-400 text-sm font-mono">
                      Mail: Beenaplastic@gmail.com
                    </p>
                  </div>
                </li>
                <li className="flex">
                  <div className="flex h-10 w-10 items-center justify-center rounded bg-blue-900 text-gray-50">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="yellow"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      className="h-6 w-6"
                    >
                      <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
                      <path d="M12 7v5l3 3"></path>
                    </svg>
                  </div>
                  <div className="ml-4 mb-4">
                    <h3 className="mb-1 text-lg font-medium leading-6 text-gray-900 dark:text-white beena">
                      Working hours
                    </h3>
                    <p className="text-gray-600 dark:text-slate-400 text-sm font-mono">
                      Monday - Friday: 08:00 - 17:00
                    </p>
                    <p className="text-gray-600 dark:text-slate-400 text-sm font-mono">
                      Saturday &amp; Sunday: 08:00 - 12:00
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="card h-fit max-w-6xl p-5 md:p-4" id="form">
            <h2 className="my-6 text-2xl font-extrabold text-yellow-300 sm:text-xl beena">Send us a Quote?</h2>
                    {/* @ts-ignore */}
                    <form id="contactForm" ref={form} onSubmit={sendEmail}>
                        <div className="mb-6">
                            <div className="mx-0 mb-1 sm:mb-4">
                                <div className="mx-0 mb-1 sm:mb-4">
                                    <label htmlFor="from_name" className="pb-1 text-xs uppercase tracking-wider"></label><input type="text" id="name" autoComplete="given-name" placeholder="Your name" className="bg-transparent mb-2 w-full rounded-md border text-yellow-300 border-yellow-300 py-2 pl-2 pr-4 shadow-md sm:mb-0" name="from_name"/>
                                </div>
                                <div className="mx-0 mb-1 sm:mb-4">
                                    <label htmlFor="from_email" className="pb-1 text-xs uppercase tracking-wider"></label><input type="email" id="email" autoComplete="email" placeholder="Your email address" className="bg-transparent mb-2 w-full rounded-md border text-yellow-300 border-yellow-300 py-2 pl-2 pr-4 shadow-md sm:mb-0" name="from_email"/>
                                </div>
                            </div>
                            <div className="mx-0 mb-1 sm:mb-4">
                                <label htmlFor="message" className="pb-1 text-xs uppercase tracking-wider"></label><textarea id="textarea" cols={30} rows={5} placeholder="Write your message..." className="bg-transparent mb-2 w-full rounded-md border text-yellow-300 border-yellow-300 py-2 pl-2 pr-4 shadow-md sm:mb-0" name="message"></textarea>
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="submit" className="w-full text-yellow-300 bg-blue-800 px-6 py-3 font-xl beena rounded-md sm:mb-0">Send Message</button>
                        </div>
                    </form>
              {/* <h2 classNameName="mb-1 text-2xl dark:text-white plastics">
                Here's where we are based off !
              </h2>
              <iframe
                width="350"
                height="350"
                loading="lazy"
                allowFullScreen
                src="https://www.google.com/maps/embed/v1/place?q=Imagicaa,+SH92,+near+Lonavala,+Khalapur,+Maharashtra,+India&key=..."
              ></iframe> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
