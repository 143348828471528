import React, { createContext, ReactNode } from 'react';
import all_product from '../components/Assests/Alllproducts';
interface ProductContextValue {
    all_product: {}; // You should replace 'any[]' with the actual type of your products
  }
  
  export const ShopContext = createContext<ProductContextValue | undefined>(undefined);
  
  interface ProductContextProviderProps {
    children: ReactNode;
  }
  
  const ProductContextProvider: React.FC<ProductContextProviderProps> = ({ children }) => {
    // Replace 'any[]' with the actual type of your products
    const contextValue: ProductContextValue = { all_product: all_product };
  
    return (
      <ShopContext.Provider value={contextValue}>
        {children}
      </ShopContext.Provider>
    );
  };
  
  export default ProductContextProvider;