import React from "react";
import profileimage from "../../resources/prdImgs/Avatar-Profile-No-Background.png";

import './testimonial.css'
export default function Testimonial() {
 
  

  return (
    <div>
      <div className=" grid mb-8 border border-gray-700 rounded-lg shadow-sm dark:border-gray-700 md:mb-12 md:grid-cols-2 bg-transparent boujee1" >
        <figure className="flex flex-col items-center justify-center p-8 text-center  border-b border-gray-700 rounded-t-lg md:rounded-t-none md:rounded-ss-lg md:border-e dark:bg-transparent dark:border-gray-700">
          <blockquote className="max-w-2xl mx-auto mb-4 text-gray-900 lg:mb-8 dark:text-gray-900">
            <h3 className="text-xl font-semibold text-yellow-300">
              <q cite="https://www.mozilla.org/en-US/about/history/details/">
              Durable and Reliable
              </q>
            </h3>
            <p className="my-4">
            "Beena plastics delivered on their promise of durability. The FRP products I received are top-notch, and the manufacturing precision is impressive. Highly recommended!"
            </p>
          </blockquote>
          <figcaption className="flex items-center justify-center ">
            <img
              className="rounded-full w-9 h-9"
              src={profileimage}
              alt="profile picture"
            />
            <div className="space-y-0.5 font-medium text-white text-left rtl:text-right ms-3">
              <div>Aarav Sharma</div>
              <div className="text-sm text-gray-900 dark:text-gray-900 ">
                Satisfied customer
              </div>
            </div>
          </figcaption>
        </figure>
        <figure className="flex flex-col items-center justify-center p-8 text-center border-b  border-gray-700 md:rounded-se-lg dark:bg-transparent dark:border-gray-700">
          <blockquote className="max-w-2xl mx-auto mb-4 text-gray-900 lg:mb-8 dark:text-gray-900">
            <h3 className="text-xl font-semibold text-yellow-300">
              <q cite="https://www.mozilla.org/en-US/about/history/details/">
              On-time and Professional
              </q>
            </h3>
            <p className="my-4">
            "I rely on beena plastics for all my FRP needs. Their timely deliveries and professional service make them stand out. Great products and a pleasure to work with!"
            </p>
          </blockquote>
          <figcaption className="flex items-center justify-center ">
            <img
              className="rounded-full w-9 h-9"
              src={profileimage}
              alt="profile picture 1"
            />
            <div className="space-y-0.5 font-medium text-white text-left rtl:text-right ms-3">
              <div>Raj Singh</div>
              <div className="text-sm text-gray-900 dark:text-gray-900">
                Construction Contractor
              </div>
            </div>
          </figcaption>
        </figure>
        <figure className="flex flex-col items-center justify-center p-8 text-center  border-b border-gray-700 md:rounded-es-lg md:border-b-0 md:border-e dark:bg-transparent dark:border-gray-700">
          <blockquote className="max-w-2xl mx-auto mb-4 text-gray-900 lg:mb-8 dark:text-gray-900">
            <h3 className="text-xl font-semibold text-yellow-300">
              <q cite="https://www.mozilla.org/en-US/about/history/details/">
              Innovative Design Solutions
              </q>
            </h3>
            <p className="my-4">
            "As an architect, I appreciate beena plastics range of FRP options. Their team provided creative solutions that enhanced my designs. Exceptional service and quality!"
            </p>
          </blockquote>
          <figcaption className="flex items-center justify-center ">
            <img
              className="rounded-full w-9 h-9"
              src={profileimage}
              alt="profile picture 2"
            />
            <div className="space-y-0.5 font-medium text-white text-left rtl:text-right ms-3">
              <div>Priya Patel</div>
              <div className="text-sm text-gray-900 dark:text-gray-900">
                Architectural Designer 
              </div>
            </div>
          </figcaption>
        </figure>
        <figure className="flex flex-col items-center justify-center p-8 text-center  border-gray-700 rounded-b-lg md:rounded-se-lg dark:bg-transparent dark:border-gray-700">
          <blockquote className="max-w-2xl mx-auto mb-4 text-gray-900 lg:mb-8 dark:text-gray-900">
            <h3 className="text-xl font-semibold text-yellow-300">
              <q cite="https://www.mozilla.org/en-US/about/history/details/">
              Precision in Industrial Applications
              </q>
            </h3>
            <p className="my-4">
            "Beena plastics is my go-to for industrial FRP components. The precision in manufacturing ensures seamless integration into our processes. Trustworthy and efficient!"
            </p>
          </blockquote>
          <figcaption className="flex items-center justify-center ">
            <img
              className="rounded-full w-9 h-9"
              src={profileimage}
              alt="profile picture 3"
            />
            <div className="space-y-0.5 font-medium text-white text-left rtl:text-right ms-3">
              <div>Meera Gupta</div>
              <div className="text-sm text-gray-900 dark:text-gray-900">
                 Industrial Engineer
              </div>
            </div>
          </figcaption>
        </figure>
      </div>
    </div>
  );
}
