import './AboutUs.css'
import backimage from "../../resources/prdImgs/bg12.jpg"
import { Link } from 'react-router-dom';
function AboutUs() {
  const backgroundStyle : React.CSSProperties= {
    backgroundImage: `url(${backimage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  
   
  };
 
  return (
    <>
    
      <div className=" bg-transparent " style={backgroundStyle}>
        <section
          id="features"
          className="relative block px-6 py-10 md:py-20 md:px-10  border-t border-b border-neutral-900 bg-neutral-900/30 "
          
        >
          <div className="relative mx-auto max-w-5xl text-center">
            <span className=" text-yellow-300 my-3 text-xl flex items-center justify-center font-medium uppercase tracking-wider text-focus-in">
              Company Profile
            </span>
            <h2 className=" block  boujee-text  w-full  font-bold 
             text-3xl sm:text-4xl">
              Providing Qualitative Products at Competitive Prices
            </h2>
            <p className=" mx-auto my-4 w-full max-w-xl bg-transparent text-center font-medium leading-relaxed tracking-wide text-slate-50">
            Established in the year 1998 beenaplastics is involved in Designing , manufacturing  installation and commissioning of chemical plant equipments and air pollution control equipment fabricated out of FRP/GRP Dual laminates or any other industrial composites  
          
            <br/>
            <br/>
            Our mission is to provide high-quality FRP products and services to our customers, meeting their needs and exceeding their expectations. We aim to be a trusted and reliable partner in the industry, delivering innovative solutions and exceptional customer service.
            </p>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="absolute scrollbtn" id="stats">
             <Link
              to="/CompanyProfile"
              type="button"
              
             
              className=" text-black bg-gradient-to-r from-amber-200 via-amber-400 to-amber-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg text-md px-4 py-2 text-center me-1 mb-2"
              >
              Read More
             </Link>
          </div> 
          </div>
          

          <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3">
            <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow ani-left">
              <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md  bg-gradient-to-r from-gradient-to-r from-amber-300 to-amber-500 p-1">
              <svg width="800px" height="800px" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <g id="Layer_2" data-name="Layer 2">
                  <g id="invisible_box" data-name="invisible box">
                 <rect width="48" height="48" fill="none"/>
                 </g>
               <g id="Health_Icons" data-name="Health Icons">
                 <g>
                 <path d="M37.7,11.1A3,3,0,0,0,35.4,10H34.2l.3-1.7A3.1,3.1,0,0,0,33.9,6a3.2,3.2,0,0,0-2.2-1H7.8a2,2,0,0,0,0,4H30.3l-4,22.9a6.8,6.8,0,0,0-1,2.1H20.7A7,7,0,0,0,7.3,34H6.2l.5-2.9a2,2,0,0,0-1.6-2.3,2,2,0,0,0-2.3,1.6L2,34.7A2.8,2.8,0,0,0,2.7,37a2.8,2.8,0,0,0,2.1,1H7.3a7,7,0,0,0,13.4,0h4.6a7,7,0,0,0,13.4,0h2a3.2,3.2,0,0,0,3.1-2.7L46,22.5ZM14,39a3,3,0,0,1-3-3,3,3,0,0,1,6,0A3,3,0,0,1,14,39ZM33.5,14h1.3l5.9,8H32.1ZM32,39a3,3,0,0,1-3-3,3,3,0,0,1,6,0A3,3,0,0,1,32,39Zm8-5H38.7A7,7,0,0,0,32,29H30.9l.5-3.1h9.9Z"/>
                 <path d="M4,15H14a2,2,0,0,0,0-4H4a2,2,0,0,0,0,4Z"/>
                 <path d="M15,19a2,2,0,0,0-2-2H5a2,2,0,0,0,0,4h8A2,2,0,0,0,15,19Z"/>
                 <path d="M6,23a2,2,0,0,0,0,4h6a2,2,0,0,0,0-4Z"/>
                      </g>
                   </g>
               </g>
               </svg>
              </div>
              <h3 className="mt-6 text-gray-100 ">On-Time Deliveries</h3>
              <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">
                We utilize use highest quality packing material in the packaging
                process to prevent any kind of harm at the time of delivery.
              </p>
            </div>

            <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow ">
              <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md  bg-gradient-to-r from-amber-300 to-amber-500 p-1">
              <svg width="800px" height="800px" viewBox="0 0 1024 1024" fill="#000000" className="icon"  version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M678.584675 765.172506v157.995691l75.697852 31.505938V723.768586a429.379161 429.379161 0 0 1-75.697852 41.40392zM269.717473 723.768586V953.098138l75.697852-31.505938v-156.419694a429.309162 429.309162 0 0 1-75.697852-41.40392zM511.999 798.78444a428.955162 428.955162 0 0 1-105.993793-13.241974v238.457534L511.999 979.886086 617.992793 1023.998V785.542466A429.025162 429.025162 0 0 1 511.999 798.78444zM511.999 0C308.479398 0 142.903721 165.575677 142.903721 369.097279S308.479398 738.192558 511.999 738.192558s369.097279-165.575677 369.097279-369.097279S715.520602 0 511.999 0z m0 660.198711c-161.345685 0-292.611428-131.265744-292.611428-292.611429 0-161.347685 131.265744-292.613428 292.611428-292.613428s292.611428 131.265744 292.611428 292.613428c0 161.347685-131.263744 292.611428-292.611428 292.611429zM511.999 135.563735c-127.93575 0-232.021547 104.083797-232.021547 232.023547S384.06325 599.606829 511.999 599.606829s232.021547-104.083797 232.021547-232.021547c0-127.93775-104.083797-232.021547-232.021547-232.021547zM607.360814 502.999018L511.999 452.865115 416.639186 502.999018l18.211965-106.183793-77.14785-75.199853 106.617792-15.49397L511.999 209.509591l47.679907 96.611811 106.617792 15.49397-77.14785 75.199853 18.211965 106.183793z" /></svg>
              </div>
              <h3 className="mt-6 text-gray-100">
                Massive Variety of Products
              </h3>
              <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">
                Excellent quality gamut of FRP Bends and fittings, FRP Tanks, FRP
                Pipe, FRP Grates, FRP Blower, FRP hood and much more.
              </p>
            </div>

            <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8  text-center shadow ani-right">
              <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md  bg-gradient-to-r from-amber-300 to-amber-500 p-1">
              <svg fill="#000000" width="800px" height="800px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M22,2V5H18V2ZM18,7h4V22H2V10L8,7v3l6-3v3h4ZM8,17H5v2H8Zm0-4H5v2H8Zm6,4H10v2h4Zm0-4H10v2h4Zm5,4H16v2h3Zm0-4H16v2h3Z"/></svg>
              </div>
              <h3 className="mt-6 text-gray-100">
                Modern Manufacturing Standards
              </h3>
              <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">
                We have an in-house Engineering & Drawing Department with
                compliance of BS, ASTM, ASME, NBC, UBC, DIN, and D/S standards.
              </p>
            </div>
            
          </div>
         
        </section>
       
      </div>
    
    </>
  );
}

export default AboutUs;
