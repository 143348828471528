import tankclose from "../../resources/images/tankclose.png";
import pipe from "../../resources/images/pipe.jpeg";
import talltank from "../../resources/images/talltank.jpeg";
import tank from "../../resources/images/tank.jpeg";

import b1 from "../../resources/prdImgs/bend1.png";
import b2 from "../../resources/prdImgs/bend2.png";
import b3 from "../../resources/prdImgs/bend3.png";

import bl1 from "../../resources/prdImgs/blower1.png";
import bl2 from "../../resources/prdImgs/blower2.png";

import g1 from "../../resources/prdImgs/grate1.png";
import g2 from "../../resources/prdImgs/grate2.png";
import g3 from "../../resources/prdImgs/grate3.png";

import i1 from "../../resources/prdImgs/item1.png";

import i2 from "../../resources/prdImgs/item2.png";

import i3 from "../../resources/prdImgs/item3.png";

import i4 from "../../resources/prdImgs/item4.png";

import j1 from "../../resources/prdImgs/join1.png";

import p1 from "../../resources/prdImgs/pipe1.png";
import p2 from "../../resources/prdImgs/29.png"; 
import p3 from "../../resources/prdImgs/17.png";

import s1 from "../../resources/prdImgs/sheet1.png";
import s2 from "../../resources/prdImgs/sheet2.png";
import s3 from "../../resources/prdImgs/sheet3.png";
import s4 from "../../resources/prdImgs/sheet4.png";

import t1 from "../../resources/prdImgs/tank.png";
import t2 from "../../resources/prdImgs/tank2.png";
import t3 from "../../resources/prdImgs/tank3.png";

import tt1 from "../../resources/prdImgs/ttank.png";
import tt2 from "../../resources/prdImgs/ttank1.png";
import tt3 from "../../resources/prdImgs/ttank2.png";

import vt1 from "../../resources/prdImgs/vtank.png";
import vt2 from "../../resources/prdImgs/vtank2.png";
import vt3 from "../../resources/prdImgs/vtank3.png";
import vt4 from "../../resources/prdImgs/vtank4.png";
import frpblower from "../../resources/prdImgs/FRP Blower.png";
import frpcoating from "../../resources/prdImgs/15.png";
import frpchimney from "../../resources/prdImgs/18.png";
import fc1 from "../../resources/prdImgs/19.png";
import fc2 from "../../resources/prdImgs/20.png";
import fc3 from "../../resources/prdImgs/21.png";
import fc4 from "../../resources/prdImgs/19.png";
let productList = [
  {
    id: 1,
    name: "FRP/GRP Pipes & Fittings",
    img: b1,
    description: "This are bends",
    allimg: [b1, b3, p1 ,p2],
    description1:
      "Beena Plastic offers our Fibre fiber-reinforced polymer (FRP) Bends, the perfect solution for industries seeking durable and high-performance pipe fittings. Crafted with advanced FRP technology, these bends offer exceptional strength, corrosion resistance, and longevity. Designed to withstand the harshest conditions, they provide seamless flow and minimize pressure drop for optimal efficiency. ",
    description2:
      "The production of FRP bends involves a combination of advanced materials, molding techniques, curing processes, and quality control measures to manufacture high-quality components suitable for various industrial applications, including piping systems, and chemical processing plants Upgrade your piping system today with our top-of-the-line FRP bends.",
    price: 35000,
    d1: ["Since the time of our inception, we have been constantly manufacturing a superior range of GRP pipes, owing to our deep industry knowledge and skilled professionals. We ensure that our products are always prepared or manufactured based on current market trends by opting for the latest tools and technologies. Our constant efforts and dedication make us the most trusted GRP pipe manufacturers, exporters, and suppliers across India. We recommend our products widely for plenty of industrial applications and can also provide them with custom specifications as needed by our clients. We believe in offering total solutions to client’s requirements, which include design, engineering services, after-sales services, supervision, maintenance, and repairs as required by the client."],
    d2: [
      "Our GRP pipes strictly resist corrosion and can withstand strict weather conditions too.",
      "In nature, they are non-toxic and do not harm our environment or mankind.",
      "Our products have a hydraulic efficiency that makes them energy-efficient and affordable.",
      "In nature, they are pretty much durable, economical, and reliable.",
      "Exceptional thermal and insulation properties enable them to work at different temperatures.",
    ],
    d3: [
      "Odor control piping",
      "Fire fighting lines",
      "Chemical process lines",
      "CoolingTower water line",
      "Water transmission and distribution (potable and raw water)",
      "Sea water intake and outfalls",
    ],
    d4: ["Major Design Standards (As Applicable): AWWA M45, BS 6464, BS 7159, ASTMD 3982, IS0 14692, API 15LR, ASMEB 31.3, DIN 16965 & 16966, AS 2634"],
    d5: [
      "sophthalic Resin",
      "Fiberglass and Silica Sand",
      "Epoxy Resin",
      "Vinyl Ester Resin",
    ],
    d6: [
      "For High Pressure Applications: ASTM D 2996",
      "As per AWWA Specifications: AWWA C 950",
      "As per BIS Specifications: IS 12709 & IS 14402",
      "For Thermoplastic Liner with GRP: DIN 16965 & DIN 16966",
    ],
  },
  {
    id: 2,
    name: "FRP/GRP Vessels & Droplet Seperator",
    img: bl1,
    description: "This are frp grates",
    description1:
      "Beena Plastic offers Fibre fiber-reinforced polymer (FRP) grate, a game-changer in the industrial and chemical factory sectors. This durable and corrosion-resistant grate is specifically designed to withstand the harshest conditions. Crafted from high-quality FRP materials, it offers exceptional strength and longevity. Its unique composition ensures maximum safety while providing efficient drainage. ",
    description2:
      "FRP grates offer design flexibility and can be customized in terms of size, shape, color, and surface texture to meet specific project requirements and aesthetic preferences. Say goodbye to traditional metal grates prone to rust and degradation. Upgrade to our FRP grate for a reliable solution that guarantees long-term performance.",
    allimg: [bl2, i3,],
    price: 35000,
    d1: ["At our organization, we uphold a steadfast commitment to excellence, offering innovative solutions tailored to meet diverse industrial needs. Our exclusive line of FRP vessels and drop separators combines efficiency and reliability to optimize air and fluid handling systems across various applications. Engineered with precision and rigorously tested for quality assurance, our FRP vessels and drop separators ensure optimal performance and durability in demanding environments. The trouble-free operation of our products is guaranteed by utilizing premium-grade raw materials sourced from industry-leading vendors. With our unwavering dedication to quality, you can trust our FRP vessels and drop separators to deliver exceptional results for your critical industrial processes."],
    d2: [
      "UV resistant",
      "Aesthetically appealing",
      "Flexibility in design",
      "Light in Weight and hence easy to handle and install",
      "Resistant to galvanic & stray electrical currents and Excellent thermal insulation",
      "Higher conveying ability due to glossy internal finish & hence less pump loads",
      "Ease of Maintenance with Zero Downtime",
      "Economical & cost effective",
    ],
    d3: ["Our FRP Droplet seperator & Vessels having a wide range of applications and sizes."],
    d4: ["Major Design Standards: BS 4994", "ASME RTP-1", "ASME Section X"],
    d5: [
      "FRP Moulded and Filament Wound using Isopthalic, Bisphenol, Vinylester and Epoxy Resin",
      "PP & HDPE Spiral Wound",
    ],
    d6: ["Qualification as per BS 4994, ASME Section X"],
  },
  {
    id: 3,
    name: "FRP/GRP Gratings",
    img: g1,
    description: "This are pipes",
    description1:
      "Beena Plastic presents high-performance Fibre Reinforced Polymer (FRP) pipes, designed specifically for industries and chemical factories. These durable pipes provide exceptional strength, corrosion resistance, and longevity, making them the ideal choice for demanding applications. Crafted using advanced FRP technology, our pipes offer superior performance and reliability, ensuring efficient fluid transport and minimizing maintenance costs.",
    description2:
      "FRP pipes have a long service life due to their durable construction and resistance to degradation from UV exposure, their lightweight design reduces the need for heavy lifting equipment and lowers installation costs, so installation is a breeze. Upgrade your infrastructure with our FRP pipes and experience long-lasting durability and exceptional performance.",
    allimg: [g1,g2,g3],
    price: 35000,
    d1: ["At our organization, we uphold a steadfast commitment to excellence, offering top-tier solutions crafted to precise specifications. Our exclusive line of FRP grating solutions is designed to cater to a wide array of applications, ranging from industrial flooring to walkways and platforms. Engineered for superior strength and durability, our FRP grating products undergo rigorous testing to ensure optimal performance and longevity. The trouble-free operation of our grating systems is guaranteed by utilizing premium-quality raw materials sourced from industry-leading vendors. With our unwavering dedication to quality, you can trust our FRP grating to provide reliable, long-lasting solutions for your industrial needs."],
    d2: [
      "Good adaptability & dimensional stability",
      "UV resistant",
      "Aesthetically appealing",
      "Flexibility in design",
      "Light in Weight and hence easy to handle and install",
      "Ease of Maintenance with Zero Downtime",
      "High Physical and Mechanical Strength",
      "High Strength to weight ratio",
    ],
    d3: ["Our FRP Pultruded Walkways, Staircase & Railing having a wide range of applications and sizes."],
    d4: ["As per customer specifications", "ASTM D 3917 - 96"],
    d5: ["FRP Pultruded Sections with Polyester Grade Resin composition with Fiberglass"],
    d6: ["As per ASTM D 3917-96"],
  },
  {
    id: 4,
    name: "FRP/GRP Blowers",
    img: frpblower,
    description: "This is frp blower",
    description1:
      "Beena Plastic offers a state-of-the-art Fibre fiber-reinforced polymer (FRP) blower, designed specifically for industrial applications. Its corrosion-resistant properties ensure longevity, making it ideal for harsh industrial environments. Its seamless design and smooth operation guarantee optimal airflow and efficient ventilation. Crafted with precision using high-quality materials, this blower delivers unmatched durability and efficiency.",
    description2:
      " The Fibre Reinforced Polymer (FRP) construction provides superior resistance to corrosion, chemicals, and harsh environmental conditions. Experience the difference with our Fibre fiber-reinforced polymer (FRP) blower today!",
    allimg: [frpblower, i2],
    price: 35000,
    d1: ["Our organization is dedicated to providing top-tier solutions tailored to meet diverse industrial needs. Our exclusive line of FRP blowers offers unparalleled performance across a multitude of applications, from chemical processing to wastewater treatment and beyond. Engineered with precision and rigorously tested for quality assurance, our FRP blowers boast exceptional durability and reliability, ensuring trouble-free operation even in the harshest environments. The longevity and strength of our blowers stem from premium-grade materials sourced from trusted industry vendors. With our commitment to excellence, you can trust our FRP blowers to deliver optimal airflow and efficiency for your critical industrial processes."],
    d2: [
      "UV resistant",
      "Aesthetically appealing",
      "Flexibility in design",
      "Light in Weight and hence easy to handle and install",
      "Higher conveying ability due to glossy internal finish & hence less pump loads.",
      "High Physical and Mechanical Strength",
      "High Strength to weight ratio",
      "Abrasion resistance",
    ],
    d3: ["Our FRP Blowers having a wide range of applications and sizes."],
    d4: [
      "ASCE/SEI 52-10",
      "BS 6464",
      "IS 875 (Wind) & IS 1893 (Earthquake) using NX and STAADPRO",
      "Blower Software: Fantech 2.0",
    ],
    d5: [
      "FRP Moulded and Filament Wound using Isopthalic, Bisphenol, Vinylester and Epoxy Resin",
      "PP & HDPE Spiral Wound",
    ],
    d6: ["Performance test by using Anemometer and Tachometer"],
  },
  {
    id: 5,
    name: "FRP/GRP Hood",
    img: i4,
    description: "This is FRP Hood",
    description1:
      "Beena Plastic presents a Fibre Reinforced Polymer (FRP) hood, a game-changer for industries. Crafted with precision, these FRP hoods are lightweight compared to traditional metal hoods, making them easier to handle, install, and maintain. Their lightweight design reduces the structural load on supporting equipment and infrastructure and their durable hood offers unmatched strength and protection. ",
    description2:
      " FRP hoods are highly resistant to corrosion from a wide range of chemicals, acids, and corrosive gases commonly found in industrial environments. This corrosion resistance ensures longevity and durability, chemicals, and extreme temperatures.Elevate your industry standards with our Fibre Reinforced Polymer (FRP) hood. Upgrade your equipment today!",
    allimg: [i4],
    price: 35000,
    d1:["Our organization is deeply committed to delivering top-tier solutions tailored to precise shapes and specifications. We proudly present an exclusive lineup of FRP hoods designed for an extensive array of industrial applications, ensuring optimal performance across various fluid environments including water, acids, alkalis, fire water, and raw water. With a steadfast focus on durability, our hoods undergo rigorous testing for strength and quality assurance prior to client delivery, utilizing premium-grade materials sourced from industry-leading vendors to guarantee trouble-free operation."],
    d2:[
      "UV resistant and aesthetically appealing design ",
      "Flexibility in customization for seamless integration ",
      "Resistant to galvanic and stray electrical currents, offering superior safety ",
      "Economical and cost-effective solution for long-term performance ",
    ],
    d3:["Our FRP hoods are available in a diverse range of applications and sizes to suit various industrial needs. "],
    d4:["Compliant with major design standards including BS 4994, ASME RTP-1, and ASME Section X. "],
    d5:["Crafted from FRP molded and filament wound using Isophthalic, Bisphenol, Vinylester, and Epoxy Resin ","PP and HDPE Spiral Wound options available for specific requirements "],
    d6:["Qualified in accordance with BS 4994 and ASME Section X standards, ensuring exceptional reliability and performance. "],
  },
  {
    id: 6,
    name: "FRP/GRP Scrubbing system",
    img: i1,
    description: "This is frp scrubbing system",
    description1:
      "Beena Plastic offers the Fibre fiber-reinforced polymer (FRP) scrubbing system, a cutting-edge pollution control device designed for industrial use. The FRP scrubbing system effectively eliminates harmful gases and particulate matter from exhaust streams, making it ideal for handling corrosive gases and chemicals commonly found in industrial processes.",
    description2:
      "Our FRP scrubbing system ensures cleaner emissions, protects the environment, and complies with regulations. Additionally, this system guarantees long-lasting performance and low maintenance as it is crafted with durable and high-quality materials. Upgrade your industrial processes with our innovative FRP scrubbing system, which delivers exceptional efficiency and environmental responsibility.",
    allimg: [i1],
    price: 35000,
    d1: ["At our organization, we prioritize excellence in providing tailored solutions to meet diverse industrial needs. Our exclusive line of FRP scrubbers offers unmatched performance across a wide spectrum of applications, including chemical processing, wastewater treatment, and environmental control. Engineered with precision and subjected to rigorous quality testing, our FRP scrubbers guarantee exceptional longevity and reliability, ensuring trouble-free operation even in the most demanding environments. The durability and effectiveness of our scrubbers are a result of utilizing premium-grade materials sourced from industry-leading vendors. With our unwavering commitment to quality, you can trust our FRP scrubbers to deliver optimal performance and efficiency for your critical industrial processes."],
    d2: [
      "Higher conveying ability due to glossy internal finish & hence less pump loads.",
      "UV resistant",
      "Aesthetically appealing",
      "Flexibility in design",
      "Light in Weight and hence easy to handle and install",
      "Good Insulating Qualities",
      "Ease of Maintenance with Zero Downtime",
      "Resistant to galvanic & stray electrical currents",
    ],
    d3: ["Our FRP Scrubbers having a wide range of applications and sizes."],
    d4: [
      "Major Design Standards: BS 4994",
      "ASME RTP-1",
      "As per standard process design",
    ],
    d5: [
      "FRP Moulded and Filament Wound using Isopthalic, Bisphenol, Vinylester and Epoxy Resin",
      "PP & HDPE Spiral Wound",
    ],
    d6: ["Qualification as per BS 4994"],
  },
  {
    id: 7,
    name: "FRP/GRP GelCoatedSheets",
    img: s1,
    description: "This are sheets",
    description1:
      "Beena Plastic presents a selection of Fibre Reinforced Polymer (FRP) gel-coated sheets, an excellent solution for industries in search of resilient and dependable materials. These sheets are meticulously crafted to offer remarkable strength and exceptional resistance against corrosion. The gel coating provides outstanding protection against chemicals, moisture, and other harsh environmental factors, making FRP gel-coated sheets perfectly suited for various industrial environments. ",
    description2:
      " Additionally, the smooth and visually appealing finish provided by the FRP gel coating enhances the overall aesthetic. Whether you require them for construction purposes or industrial use. Invest in quality and durability with our Fibre Reinforced Polymer (FRP) gel-coated sheets.",
    allimg: [s1, s2, s3, s4],
    price: 35000,
    d1: [
      "At our organization, we prioritize excellence in providing tailored solutions to meet diverse industrial needs. Our exclusive line of FRP sheets offers unmatched versatility and reliability for a wide range of applications, including construction, transportation, and architectural projects. Engineered for exceptional strength and durability, our FRP sheets undergo rigorous quality testing to ensure consistent performance and longevity. The trouble-free installation and performance of our sheets are guaranteed by utilizing premium-grade raw materials sourced from industry-leading vendors. With our unwavering commitment to quality, you can trust our FRP sheets to deliver superior results for your most demanding projects.",
    ],
    d2: [
      "Large view glasses are possible to fix with the Insulated panel doors without any extra support",
      "Lower thermal conductivity",
      "Good adaptability & dimensional stability",
      "UV resistant",
      "Aesthetically appealing",
      "Flexibility in design",
      "Light in Weight and hence easy to handle and install",
      "Ease of Maintenance with Zero Downtime",
    ],
    d3: ["Our FRP GelCoated Sheet having a wide range of applications and sizes."],
    d4: ["Flat Sheet", "Trapezoidal Sheet", "Corrugated sheet"],
    d5: ["FRP Moulded using Polyester Grade Resin with Fiberglass"],
    d6: ["Qualification as per Customer specifications"],
  },
  {
    id: 8,
    name: "FRP/GRP Tanks",
    img: t1,
    description: "This is a  PP-FRP tanks",
    description1:
      "Beena Plastic offers the Pp frp tank, a must-have for industries and chemical factories. This high-quality tank combines the durability of PP (polypropylene) with the strength of FRP (fiberglass reinforced plastic). With its corrosion-resistant properties, it ensures safe storage of chemicals and liquids. PP-FRP tanks are made from a combination of polypropylene (PP) and fiberglass reinforcement. Polypropylene provides chemical resistance, while fiberglass reinforcement enhances the structural strength of the tank. The selection of materials depends on the type of chemicals stored and the operating conditions. perfectly suited for industrial use",
    description2:
      "PP-FRP tanks typically require minimal maintenance due to their corrosion resistance and durability. However, periodic inspections and maintenance tasks may be necessary to ensure continued performance and longevity. Upgrade your storage capabilities with this reliable and long-lasting solution. Invest in the Pp frp tank today and enjoy peace of mind knowing your liquids are stored securely.",
    allimg: [t1, t2, vt1, vt2, vt3, vt4],
    price: 35000,
    d1: [
      "Our organization strictly commits to delivering high-quality solutions in customized shapes or specifications. We offer an exclusive range of FRP storage tanks for a wide range of fluid applications, including water, acids, alkalis, fire water, raw water, etc. We ensure the long-term life of our tanks through high strength and rigorous testing on various quality parameters before delivering them to clients. Quality raw materials from leading vendors in the industry guarantee the trouble-free performance of our storage tanks.",
    ],
    d2: [
      "UV resistant",
      "Aesthetically appealing",
      "Flexibility in design",
      "Light in Weight and hence easy to handle and install",
      "Resistant to galvanic & stray electrical currents and Excellent thermal insulation",
      "Higher conveying ability due to glossy internal finish & hence less pump loads",
      "Ease of Maintenance with Zero Downtime",
      "Economical & cost effective",
    ],
    d3: [
      "Our FRP tanks having a wide range of applications and sizes.",
    ],
    d4: ["Major Design Standards: BS 4994", "ASME RTP-1", "ASME Section X"],
    d5: [
      "FRP Moulded and Filament Wound using Isopthalic, Bisphenol, Vinylester and Epoxy Resin",
      "PP & HDPE Spiral Wound",
    ],
    d6: ["Qualification as per BS 4994, ASME Section X"],
  },
  {
    id: 9,
    name: "FRP/GRP Coatings",
    img: frpcoating,
    description: "This are frp grates",
    description1:
      "Beena Plastic offers Fibre fiber-reinforced polymer (FRP) grate, a game-changer in the industrial and chemical factory sectors. This durable and corrosion-resistant grate is specifically designed to withstand the harshest conditions. Crafted from high-quality FRP materials, it offers exceptional strength and longevity. Its unique composition ensures maximum safety while providing efficient drainage. ",
    description2:
      "FRP grates offer design flexibility and can be customized in terms of size, shape, color, and surface texture to meet specific project requirements and aesthetic preferences. Say goodbye to traditional metal grates prone to rust and degradation. Upgrade to our FRP grate for a reliable solution that guarantees long-term performance.",
    allimg: [frpcoating],
    price: 35000,
  d1:["Our organization is steadfastly committed to delivering premium solutions customized to precise shapes or specifications. We proudly present an exclusive array of FRP/GRP coatings, tailored to meet diverse industrial needs with unparalleled reliability and performance. Our coatings are engineered to withstand a myriad of fluid applications, including water, acids, alkalis, fire water, and raw water, ensuring longevity and optimal functionality. Rigorous testing on various quality parameters guarantees the durability and trouble-free performance of our coatings, underpinned by the use of top-quality raw materials sourced from industry-leading vendors. "],
    d2:[
      "UV resistant and aesthetically appealing, enhancing visual appeal ",
      "Flexible design options for seamless integration into diverse environments ",
      "Resistant to galvanic and stray electrical currents, offering superior safety ",
      "Economical and cost-effective solution for long-term performance ",
    ],
    d3:["Our FRP/GRP coatings offer a wide range of applications and sizes to suit various industrial requirements. "],
    d4:["Compliant with major design standards including BS 4994, ASME RTP-1, and ASME Section X.  "],
    d5:["Crafted from FRP molded and filament wound using Isophthalic, Bisphenol, Vinylester, and Epoxy Resin ","PP and HDPE Spiral Wound options available for specific requirements "],
    d6:["Qualified in accordance with BS 4994 and ASME Section X standards, ensuring exceptional reliability and performance. "],
  }
];
export default productList;
