import React, { useState } from "react";
import backimage from "../../resources/prdImgs/gradientbg.jpg"
import './statistics.css'
function Statistics() {
  const backgroundStyle = {
    backgroundImage: `url(${backimage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  };
  const [Activenum, setActiveNum] = useState<Number>(0);
  const [toggle, setToggle] = useState<Number>(0);

  const handleChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: Number
  ) => {
      
      setActiveNum(index);
  };

  const handleChange1 = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const index = parseInt(event.target.value);
    setActiveNum(index);
  };
  const toggleAccord = (index:Number) => {
      if(index === toggle) setToggle(0);
      else setToggle(index);
  };

  return (
    <div>
      <div className="mb-10 w-full boujee border border-gray-700 rounded-lg shadow  dark:border-gray-700">
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select tab
          </label>
          <select
            onChange={handleChange1}
            id="tabs"
            className="bg-black border-0 border-b border-gray-600 text-yellow-300 text-sm rounded-t-lg focus:ring-yellow-500 focus:border-yellow-500 block w-full p-2.5 dark:bg-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-yellow-300 dark:focus:ring-yellow-500 dark:focus:border-yellow-500"
          >
            <option value={0}>
              Statistics
            </option>
            <option value={1}>
              Services
            </option>
            <option value={2}>
              FAQ
            </option>
          </select>
        </div>
        <ul
          className="hidden text-sm font-medium text-center text-zinc-900 divide-x divide-gray-200 rounded-lg sm:flex dark:divide-gray-600 dark:text-gray-400 rtl:divide-x-reverse"
          id="fullWidthTab"
          data-tabs-toggle="#fullWidthTabContent"
          role="tablist"
        >
          <li className="w-full">
            <button
              onClick={(event) => handleChange(event, 0)}
              id="stats-tab"
              data-tabs-target="#stats"
              type="button"
              role="tab"
              aria-controls="stats"
              aria-selected="true"
              className="inline-block w-full p-4 rounded-ss-lg bg-transparent hover:bg-gray-600 focus:outline-none dark:bg-transparent dark:hover:bg-gray-600 text-yellow-300"
            >
              Statistics
            </button>
          </li>
          <li className="w-full">
            <button
              onClick={(event) => handleChange(event, 1)}
              id="about-tab"
              data-tabs-target="#about"
              type="button"
              role="tab"
              aria-controls="about"
              aria-selected="false"
              className="inline-block w-full p-4 bg-transparent hover:bg-gray-600 focus:outline-none dark:bg-transparent dark:hover:bg-gray-600 text-yellow-300"
            >
              Services
            </button>
          </li>
          <li className="w-full">
            <button
              onClick={(event) => handleChange(event, 2)}
              id="faq-tab"
              data-tabs-target="#faq"
              type="button"
              role="tab"
              aria-controls="faq"
              aria-selected="false"
              className="inline-block w-full p-4 rounded-se-lg bg-transparent hover:bg-gray-600 focus:outline-none dark:bg-transparent dark:hover:bg-gray-600 text-yellow-300"
            >
              FAQ
            </button>
          </li>
        </ul>
        <div
          id="fullWidthTabContent"
          className="border-t border-gray-200 dark:border-gray-600"
        >
          <div
            className={
              Activenum === 0
                ? "p-4 bg-transparent rounded-lg md:p-8 dark:bg-transparent"
                : "hidden"
            }
            id="stats"
            role="tabpanel"
            aria-labelledby="stats-tab"
          >
            <dl className="grid max-w-screen-xl grid-cols-2 gap-8 p-4 mx-auto text-gray-900 sm:grid-cols-3 xl:grid-cols-4 dark:text-white sm:p-8">
            <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-3xl font-extrabold text-yellow-300">
                  100+
                </dt>
                <dd className="text-zinc-900 ">
                  Employees
                </dd>
              </div>
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-3xl font-extrabold text-yellow-300">
                  25+
                </dt>
                <dd className="text-zinc-900 ">
                  Experience in Sector
                </dd>
              </div>
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-3xl font-extrabold text-yellow-300">
                  20Cr+
                </dt>
                <dd className="text-zinc-900 ">
                  Annual Turnover
                </dd>
              </div>
              <div className="flex flex-col items-center justify-center">
                <dt className="mb-2 text-3xl font-extrabold text-yellow-300">
                  1998
                </dt>
                <dd className="text-zinc-900 ">
                  Year of Establishment
                </dd>
              </div>
              
            </dl>
          </div>
          <div
            className={
              Activenum === 1
                ? "p-4 bg-transparent rounded-lg md:p-8 dark:bg-transparent"
                : "hidden"
            }
            id="about"
            role="tabpanel"
            aria-labelledby="about-tab"
          >
            <h2 className="mb-5 text-2xl font-extrabold text-yellow-300 tracking-tight">
              List of services we offer
            </h2>
            <ul
              role="list"
              className="space-y-4 text-zinc-900 "
            >
              <li className="flex space-x-2 rtl:space-x-reverse items-center">
                <svg
                  className="flex-shrink-0 w-3.5 h-3.5 text-yellow-600 dark:text-yellow-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="leading-tight">
                Custom FRP Fabrication
                </span>
              </li>
              <li className="flex space-x-2 rtl:space-x-reverse items-center">
                <svg
                  className="flex-shrink-0 w-3.5 h-3.5 text-yellow-600 dark:text-yellow-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="leading-tight">FRP Molded Products</span>
              </li>
              <li className="flex space-x-2 rtl:space-x-reverse items-center">
                <svg
                  className="flex-shrink-0 w-3.5 h-3.5 text-yellow-600 dark:text-yellow-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="leading-tight">FRP Composite Panels</span>
              </li>
              <li className="flex space-x-2 rtl:space-x-reverse items-center">
                <svg
                  className="flex-shrink-0 w-3.5 h-3.5 text-yellow-600 dark:text-yellow-500"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                </svg>
                <span className="leading-tight">
                   FRP Repair and Restoration
                </span>
              </li>
            </ul>
          </div>
          <div
            className={
              Activenum === 2
                ? "p-4 bg-transparent rounded-lg md:p-8 dark:bg-transparent"
                : "hidden"
            }
            id="faq"
            role="tabpanel"
            aria-labelledby="faq-tab"
          >
            <div
              id="accordion-flush"
              data-accordion="collapse"
              data-active-classNamees="bg-white dark:bg-transparent text-gray-900 dark:text-white"
              data-inactive-classNamees="text-gray-500 dark:text-gray-400"
            >
              <h2 id="accordion-flush-heading-1">
                <button
                  onClick={(event) => {
                    toggleAccord(1);
                  }}
                  type="button"
                  className="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-zinc-800  border-b border-gray-200 dark:border-gray-700 "
                  data-accordion-target="#accordion-flush-body-1"
                  aria-expanded="true"
                  aria-controls="accordion-flush-body-1"
                >
                  <span>What is beenaplastics?</span>
                  <svg
                    data-accordion-icon
                    className="w-3 h-3 rotate-180 shrink-0 text-yellow-300"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              {toggle === 1 && (
                <div
                  id="accordion-flush-body-1"
                  aria-labelledby="accordion-flush-heading-1"
                >
                  <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                    <p className="mb-2 text-zinc-800 ">
                    Beena Plastic is a well-known manufacturing company specializing in the fabrication of top-notch Fibre Reinforced Plastic or Polymer goods. With extensive experience in the field, Beena Plastic has established itself as a reliable brand, delivering excellence in both design and functionality. The company is recognized for its commitment to precision and meticulousness, ensuring that every product adheres to the highest quality standards. 
                    </p>
                    
                  </div>
                </div>
              )}
              <h2 id="accordion-flush-heading-2">
                <button
                  onClick={(event) => {
                    toggleAccord(2);
                  }}
                  type="button"
                  className="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-zinc-800 border-b border-gray-200 dark:border-gray-700 "
                  data-accordion-target="#accordion-flush-body-2"
                  aria-expanded="false"
                  aria-controls="accordion-flush-body-2"
                >
                  <span>Why invest in beenaplastics?</span>
                  <svg
                    data-accordion-icon
                    className="w-3 h-3 rotate-180 shrink-0 text-yellow-300"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              {toggle === 2 && (
                <div
                  id="accordion-flush-body-2"
                  aria-labelledby="accordion-flush-heading-2"
                >
                  <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                    <p className="mb-2 text-zinc-800 ">
                    Beena Plastic has acquired extensive knowledge in delivering tailored solutions to meet the specific needs of its clients over time. Creative and unique solutions are developed through a comprehensive examination of customer requirements. The systems are designed to offer versatility in operations while adhering strictly to international standards. With ongoing support from top companies in India, Beena Plastics is the optimal choice for air pollution control and FRP-GRP equipment.

                    </p>
                  
                  </div>
                </div>
              )}
              <h2 id="accordion-flush-heading-3">
                <button
                  onClick={(event) => {
                    toggleAccord(3);
                  }}
                  type="button"
                  className="flex items-center justify-between w-full py-5 font-medium text-left rtl:text-right text-zinc-800 border-b border-gray-200 dark:border-gray-700 "
                  data-accordion-target="#accordion-flush-body-3"
                  aria-expanded="false"
                  aria-controls="accordion-flush-body-3"
                >
                  <span>
                    What are the differences between FRP and Other materials?
                  </span>
                  <svg
                    data-accordion-icon
                    className="w-3 h-3 rotate-180 shrink-0 text-yellow-300"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 5 5 1 1 5"
                    />
                  </svg>
                </button>
              </h2>
              {toggle === 3 && (
                <div
                  id="accordion-flush-body-3"
                  aria-labelledby="accordion-flush-heading-3"
                >
                  <div className="py-5 border-b border-gray-200 dark:border-gray-700">
                    <p className="mb-2 text-zinc-800 ">
                    Fiber Reinforced Polymer (FRP) materials differ from other traditional materials such as metals, wood, and concrete in several ways. FRP offers high corrosion resistance, lightweight design, strength, durability, and design flexibility. It is non-conductive and has low thermal conductivity, making it suitable for various applications including corrosive environments, construction, and electrical insulation. Compared to metals, wood, and concrete, FRP provides a versatile and reliable solution for a wide range of industrial and commercial applications. Our fabrication company, at the forefront of the industry, delivers unmatched precision and innovation. With cutting-edge technology and a dedicated team, we redefine standards and exceed expectations. From custom metal fabrication to structural solutions, our commitment to excellence ensures superior results. Clients worldwide trust us as the preferred choice for turning visions into reality. Choose us for reliability, expertise, and transformative solutions that elevate your projects
                    </p>
                   
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statistics;
