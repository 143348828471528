import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    

    return (
        
<footer
  className="mt-48 text-center text-amber-300 navbarBg lg:text-left">
  <div
    className="flex items-center justify-center border-b-2 border-amber-300 p-6 dark:border-amber-400 lg:justify-between">
    <div className="mr-12 hidden lg:block">
      <span>Get connected with us on social networks:</span>
    </div>
    <div className="flex justify-end">
      <Link to="https://www.linkedin.com/in/anuj-yadav-87227b23a/" className="text-gray-300 dark:text-gray-300">
        <svg
         
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          fill="currentColor"
          viewBox="0 0 24 24">
          <path
            d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z" />
        </svg>
      </Link>
     
    </div>
  </div>

  <div className="mx-6 py-10 text-center md:text-left">
    <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
     
      <div className="">
        <h6
          className="mb-4 flex items-center justify-center font-semibold uppercase md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-4 w-4">
            <path
              d="M12.378 1.602a.75.75 0 00-.756 0L3 6.632l9 5.25 9-5.25-8.622-5.03zM21.75 7.93l-9 5.25v9l8.628-5.032a.75.75 0 00.372-.648V7.93zM11.25 22.18v-9l-9-5.25v8.57a.75.75 0 00.372.648l8.628 5.033z" />
          </svg>
          About us
        </h6>
        <p className="text-gray-300">
        Established in the year 1998 beenaplastics is involved in Designing , manufacturing  installation and commissioning of chemical plant equipments and air pollution control equipment fabricated out of FRP/GRP Dual laminates or any other industrial composites  

        </p>
      </div>
      
      <div className="">
        <h6
          className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
          Products
        </h6>
        <p className="mb-4">
          <Link to="/SingleProduct/1" className="text-gray-300"  
            >FRP/GRP Pipes & Fittings</Link>
        </p>
        <p className="mb-4">
          <Link to="/SingleProduct/8" className="text-gray-300"  
            >FRP/GRP Tanks</Link>
        </p>
        <p className="mb-4">
          <Link to="/SingleProduct/7" className="text-gray-300" 
            >FRP/GRP Gelcoatedsheets</Link>
        </p>
        <p>
          <Link to="/SingleProduct/3" className="text-gray-300"  
            >FRP/GRP Gratings</Link>
        </p>
        <br />
         <p>
         <Link to="/Products" className="text-gray-300"  
            >All Products</Link>
         </p>
       
        
      </div>
      
      <div className="">
        <h6
          className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
          Useful links
        </h6>
        <p className="mb-4">
          <Link to="/" className="text-gray-300 dark:text-gray-300"  
            >Home</Link>
        </p>
        <p className="mb-4">
          <Link to="/CompanyProfile" className="text-gray-300 dark:text-gray-300"  
            >Company Profile</Link >
        </p>
        <p className="mb-4">
          <Link to="/CertiAndCLients" className="text-gray-300 dark:text-gray-300"  
            >Certificates</Link >
        </p>
        <p>
          <Link to="/contact" className="text-gray-300 dark:text-gray-300" 
            >Contact</Link >
        </p>
      </div>
     
      <div>
        <h6
          className="mb-4 flex justify-center font-semibold uppercase md:justify-start">
          Contact
        </h6>
        <p className="text-gray-300 dark:text-gray-300 mb-4 flex items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5" >
            <path
              d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
            <path
              d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
          </svg>
          Neelkanth industries
          Plot no 13, Village Dhamni,
          Taluka: khalapur,
          Dist: Raigad
          Pincode: 410203
        </p>
        <p className=" text-gray-300 dark:text-gray-300 mb-4 flex items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5">
            <path
              d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path
              d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
          </svg>
          beenaplastic@gmail.com
        </p>
        <p className="text-gray-300 dark:text-gray-300 mb-4 flex items-center justify-center md:justify-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mr-3 h-5 w-5">
            <path
              fillRule="evenodd"
              d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
              clipRule="evenodd" />
          </svg>
          + 91 8999019404
        </p>
        
      </div>
    </div>
  </div>
{/* amber 700 */}
  <div className="bg-gray-900 p-6 text-center dark:bg-black">
    <span className='font dark:text-gray-300'>© 2024    Developed by:</span>
    <Link
      className="font-semibold text-gradient-to-r "
      to="http://quetraware.com/"
      > QUETRAWARE </Link>
  </div>
</footer>
  )  
}

export default Footer
