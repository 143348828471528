import React , { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import backimage from "../../resources/images/logo_sm.png"
import { Dialog, Disclosure, Popover, Transition } from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";
import companyLogo from "../../resources/images/logo_sm.png";

const companyName: string = "Beena Plastics";
const moreAboutUs = [
  {
    name: "Company Profile",
    description: "Your customers’ data will be safe and secure",
    href: "/CompanyProfile",
    icon: FingerPrintIcon,
  },
  {
    name: "Why us?",
    description: "Things that make us better than our competitors",
    href: "/whyus",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "Certificates",
    description: "Your customers’ data will be safe and secure",
    href: "/CertiAndCLients",
    icon: FingerPrintIcon,
  },
  {
    name: "Design Standards",
    description: "Your customers’ data will be safe and secure",
    href: "/designStandard",
    icon: FingerPrintIcon,
  },
  
];
const products = [
  {
    name: "FRP/GRP Pipes & Fittings",
    description: "Best quality pipes",
    href: "/SingleProduct/1",
    icon: ChartPieIcon,
  },
  {
    name: "FRP/GRP Tanks",
    description: "Best quality tank",
    href: "/SingleProduct/8",
    icon: CursorArrowRaysIcon,
  },
  {
    name: "FRP/GRP Vessels & droplet seperator",
    description: "Best quality sheets",
    href: "/SingleProduct/2",
    icon: FingerPrintIcon,
  },
  {
    name: "FRP/GRP Gratings",
    description: "Best quality Grating",
    href: "/SingleProduct/3",
    icon: FingerPrintIcon,
  },
  {
    name: "FRP/GRP Gelcoatedsheets",
    description: "Best quality bends",
    href: "/SingleProduct/7",
    icon: FingerPrintIcon,
  },
  {
    name: "FRP/GRP Blowers",
    description: "Best quality blower",
    href: "/SingleProduct/4",
    icon: FingerPrintIcon,
  },
  {
    name: "FRP/GRP Hoods",
    description: "Best quality hood",
    href: "/SingleProduct/5",
    icon: FingerPrintIcon,
  },
 
  {
    name: "FRP/GRP Scrubbing System",
    description: "Best quality coating",
    href: "/SingleProduct/6",
    icon: FingerPrintIcon,
  },
  {
    name: "FRP/GRP Coating Services",
    description: "Best quality coating",
    href: "/SingleProduct/9",
    icon: FingerPrintIcon,
  },

  {
    name: "...Many More",
    description: "many more products",
    href: "/Products",
    icon: ChartPieIcon,
  },
];

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  
  
  
  return (
    <>
 
   
   <header className="bg-transparent fixed top-0 z-30 navbar box-border">
      <nav
        className="mx-auto flex items-center justify-between p-4 lg:p-6 navbar box-border "
        aria-label="Global"
      >
        <div className="flex gap-x-2 items-center text-amber-300">
          <a href="#" className="-m-1.5 " >
            <span className="sr-only">{companyName}</span>
            <img className="h-10 lg:h-12 w-auto" loading="lazy" src={companyLogo} alt="" />
          </a>
          <div className="logoHome lg:hidden block">
            <div className="logo-name text-white -top-6 w-48 text-3xl">
              Beena Plastics
            </div>
            <span className="text-sm lg:text-base left-1/4 bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-200 font-bold">
              evolving everyday.
            </span>
          </div>
        </div>
        <div className="logoHome hidden lg:block">
          <div className="logo-name text-white -top-8 w-48 text-4xl">
            Beena Plastics
          </div>
          <span className="text-sm lg:text-base left-1/3 bg-clip-text text-transparent bg-gradient-to-r from-yellow-500 to-yellow-200 font-bold">
            evolving everyday.
          </span>
        </div>
        <div className="flex">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-amber-300"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            {/* <Bars3Icon className="h-6 w-6" aria-hidden="true" /> */}
            <svg
            className='w-6 h-6 lg:w-9 lg:h-9'
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 3H43M3 23H43M25.5 43H43"
                stroke="#FFD600"
                stroke-width="5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </nav>
      <div
        className={`top-0 right-0 w-full sm:max-w-sm fixed h-full z-40  ease-in-out duration-300 ${
          mobileMenuOpen ? "translate-x-0 " : "translate-x-full"
        }`}
      >
        <div className="fixed inset-0 z-10" />
        <div className="hide-scroll-bar z-40 fixed inset-y-0 right-0 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">{companyName}</span>
              <img className="h-8 w-auto" src={companyLogo} alt="" />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-amber-300"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-10 w-10" aria-hidden="true" />
            </button>
          </div>
          <div className=" mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-7000/10">
              <div className="space-y-2 py-6">
                <Link
                  to="/"
                  onClick={() => {
                    setMobileMenuOpen(false);
                   
                  }}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-amber-300/70 hover:text-amber-400 hover:bg-gray-700"
                >
                  Home
                </Link>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-amber-300/70 hover:text-amber-400 hover:bg-gray-700">
                        About us
                        <ChevronDownIcon
                          className="h-5 w-5 flex-none text-gray-400"
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...moreAboutUs].map((item) => (
                          <Link
                            to={item.href}
                            onClick={() => {
                              setMobileMenuOpen(false);
                            
                            }}
                          >
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              // href={item.href}
                              className="block rounded-lg py-2 pl-6 pr-3 text-xs font-semibold leading-7 text-cyan-100/60 hover:text-amber-300 hover:bg-gray-700"
                            >
                              {item.name}
                            </Disclosure.Button>
                          </Link>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-amber-300/70 hover:text-amber-400 hover:bg-gray-700">
                        Our Products
                        <ChevronDownIcon
                          className="h-5 w-5 flex-none text-gray-400"
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...products].map((item) => (
                          <Link
                            to={item.href}
                            onClick={() => {
                              setMobileMenuOpen(false);
                            
                            }}
                          >
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              // href={item.href}
                              className="block rounded-lg py-2 pl-6 pr-3 text-xs font-semibold leading-7 text-cyan-100/60 hover:text-amber-300 hover:bg-gray-700"
                            >
                              {item.name}
                            </Disclosure.Button>
                          </Link>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <Link
                  to="/Products"
                  onClick={() => {
                    setMobileMenuOpen(false);
                  
                  }}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-amber-300/70 hover:text-amber-400 hover:bg-gray-700"
                >
                  All products
                </Link>
              </div>
              <div className="py-6">
                <Link
                  to="/contact"
                  onClick={() => {
                    setMobileMenuOpen(false);
                   
                  }}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-cyan-100 hover:bg-gray-700 boujee-text"
                >
                  Contact us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    
    </>
                  );
}

{
  /* <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Link to="/" className="flex justify-center items-center gap-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 text-amber-300"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>

            <span className="text-sm font-semibold leading-6 text-amber-300">
              Home
            </span>
          </Link>

          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-amber-300">
              About us
              <ChevronDownIcon
                className="h-5 w-5 flex-none"
                aria-hidden="true"
              />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-black shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {products.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-700"
                    >
                      {/* <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-700 group-hover:bg-white">
                        <item.icon
                          className="h-6 w-6 text-gray-600 group-hover:text-indigo-600"
                          aria-hidden="true"
                        />
                      </div> */
}
//             <div className="flex-auto">
//               <Link
//                 to={item.href}
//                 className="block font-semibold text-amber-300"
//               >
//                 {item.name}
//                 <span className="absolute inset-0" />
//               </Link>
//               <p className="mt-1 text-amber-100">
//                 {item.description}
//               </p>
//             </div>
//           </div>
//         ))}
//       </div>
//     </Popover.Panel>
//   </Transition>
// </Popover>

//   <Link
//     to="/SingleProduct/"
//     className="text-sm font-semibold leading-6 text-amber-300"
//   >
//     Our Products
//   </Link>
// </Popover.Group>
// <div className="hidden lg:flex lg:flex-1 lg:justify-end">
//   <Link
//     to="/contact"
//     className="text-md font-semibold leading-6 text-amber-300"
//   >
//     Contact us <span aria-hidden="true">&rarr;</span>
//   </Link>
// </div> */}
