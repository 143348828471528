import "../customCss/home.css";
import "../customCss/note.css";
import ModelLoader from "../components/ModelLoader";
import Clients from "../components/Clients";
import AboutUs from "../components/AboutUs/AboutUs";
import SmallCTA from "../components/SmallCTA";
import LargeCTA from "../components/LargeCTA";
import Leadership from "../components/Leadership";
import Statistics from "../components/Statistics/statistics";
import Testimonial from "../components/Testimonial/Testimonial";
function Home() {
  return (
    <div className="home">
      <div className="hero">
        <div id="canvas" className="-z-50">
          <ModelLoader />
        </div>
        <div className="scrollbtn" id="stats">
          <a
            href="#stats"
            type="button"
            className=" text-black bg-gradient-to-r from-amber-200 via-amber-400 to-amber-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-amber-300 dark:focus:ring-amber-800 shadow-lg shadow-amber-500/50 dark:shadow-lg dark:shadow-amber-800/80 font-medium rounded-lg text-md px-4 py-2 text-center me-1 mb-2"
          >
            Scroll Down
          </a>
        </div>
      </div>
      <div className="container mx-auto px-4 mt-32">
        <AboutUs />
      </div>
      <div className="container mx-auto px-4 smallCTA lz-animation">
        <SmallCTA />
      </div>
      <div className="container mx-auto px-4 ">
        <Statistics />
      </div>
      <div className="container mx-auto px-4 largeCTA mt-20  lz-animation">
        <LargeCTA />
      </div>
      <div className="container mx-auto px-4 mt-32">
        <Clients />
      </div>
      <div className="leadership container mx-auto px-4 smallCTA lz-animation">
        <Leadership />
      </div>
    
      <div className="container mx-auto px-4 lz-animation">
        <Testimonial />
      </div>
    </div>
  );
}

export default Home;
