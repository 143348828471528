import backimage from '../resources/prdImgs/bg12.jpg'

function CompanyProfile() {
  const backgroundStyle : React.CSSProperties= {
    backgroundImage: `url(${backimage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  
   
  };
  return (
    <>

    <div className="bg-transparent pt-10 pb-44"  style={backgroundStyle}>
      <div className=" mt-28 container mx-auto px-4" >
        <h2 className="text-4xl font-extrabold text-black text-yellow-300 sm:text-3xl">
          ABOUT US
        </h2>
        <p className="text-md mt-8 text-slate-50 ">
          Established in the year 1998 beenaplastics is involved in Designing , manufacturing  installation and commissioning of chemical plant equipments and air pollution control equipment fabricated out of FRP/GRP Dual laminates or any other industrial composites  We also provide FRP Services & Hot Insulation
          Service to our clients.
        </p>
        <p className="text-md mt-8 text-slate-50 ">
          Our professionals are highly knowledgeable and efficient who supports
          us to execute all our business processes in an organized manner. We
          have been able to achieve highest level of client satisfaction under
          the supervision of our mentor, Mr. Thakur Prasad Yadav (Chairman), who
          holds years of knowledge in this domain.
        </p>

        <div className="mt-10 flex flex-col lg:flex-row justify-between">
          <div>
            <h2 className="mt-10 text-2xl font-extrabold  text-yellow-300 sm:text-xl">
              INDUSTRIES WE SERVE
            </h2>
            <p className="text-md mt-2 text-slate-50 ">
              Infrastructure, Water & Sanitation
            </p>
            <p className="text-md mt-2 text-slate-50 ">Power & Energy</p>
            <p className="text-md mt-2 text-slate-50 ">
              Chemical, Petrochemical, Pharmaceutical
            </p>
            <p className="text-md mt-2 text-slate-50 ">Oil & Gas, Offshore</p>
            <p className="text-md mt-2 text-slate-50 ">Electrical</p>
            <p className="text-md mt-2 text-slate-50 ">
              Building & Construction
            </p>
            <p className="text-md mt-2 text-slate-50 ">Mass Transportation</p>
            <p className="text-md mt-2 text-slate-50 ">Cooling Tower</p>
            <p className="text-md mt-2 text-slate-50 ">Defense</p>
            <p className="text-md mt-2 text-slate-50 ">Marine</p>
            <p className="text-md mt-2 text-slate-50 ">Steel</p>
          </div>
          <div>
            <h2 className="mt-10 text-2xl font-extrabold  text-yellow-300 sm:text-xl">
              Statutory Profile
            </h2>
            <p className="text-md mt-2 text-slate-50 ">
              Banker :STATE BANK OF INDIA, AXIS BANK
            </p>
            <p className="text-md mt-2 text-slate-50 ">
              GST No. :27ABCPY8818K1ZW
            </p>
            <p className="text-md mt-2 text-slate-50 ">
              Packaging/Payment and Shipment Details
            </p>
            <p className="text-md mt-2 text-slate-50 ">
              Customized Packaging :Yes
            </p>
            <p className="text-md mt-2 text-slate-50 ">
              Payment Mode :Cash / Credit Card / Cheque / DD
            </p>
            <p className="text-md mt-2 text-slate-50 ">
              Shipment Mode :By Road
            </p>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default CompanyProfile;
