import { Link } from "react-router-dom";
import { useEffect } from 'react';

function History() {
// @ts-ignore
  let cardLeft,cardRight;
  const observerLeft = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('fade-in-right');
        return;
      }
  
      entry.target.classList.remove('fade-in-right');

    });
  });
  const observerRight = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('fade-in-left');
        return;
      }
  
      entry.target.classList.remove('fade-in-left');

    });
  });
  
  useEffect(() => {
    cardLeft = document.querySelectorAll('.card-left');
    cardRight = document.querySelectorAll('.card-right');
    
    cardLeft.forEach((element) => {observerLeft.observe(element)});
    cardRight.forEach((element) => {observerRight.observe(element)});
    
    return () => {
      //@ts-ignore
      cardLeft.forEach((element) => {observerLeft.unobserve(element)});
      //@ts-ignore
      cardRight.forEach((element) => {observerRight.unobserve(element)});
    }
  }, [])


  const events = [
    {
      date: "1-6 May, 2021",
      event: "Registration",
      desc: "Pick your favourite event(s) and register in that event by filling the form corresponding to that event. Its that easy :)",
    },
    {
      date: "1-6 May, 2021",
      event: "Registration",
      desc: "Pick your favourite event(s) and register in that event by filling the form corresponding to that event. Its that easy :)",
    },
    {
      date: "1-6 May, 2021",
      event: "Registration",
      desc: "Pick your favourite event(s) and register in that event by filling the form corresponding to that event. Its that easy :)",
    },
    {
      date: "1-6 May, 2021",
      event: "Registration",
      desc: "Pick your favourite event(s) and register in that event by filling the form corresponding to that event. Its that easy :)",
    },
    {
      date: "1-6 May, 2021",
      event: "Registration",
      desc: "Pick your favourite event(s) and register in that event by filling the form corresponding to that event. Its that easy :)",
    },
    {
      date: "1-6 May, 2021",
      event: "Registration",
      desc: "Pick your favourite event(s) and register in that event by filling the form corresponding to that event. Its that easy :)",
    },
    {
      date: "1-6 May, 2021",
      event: "Registration",
      desc: "Pick your favourite event(s) and register in that event by filling the form corresponding to that event. Its that easy :)",
    },
    {
      date: "1-6 May, 2021",
      event: "Registration",
      desc: "Pick your favourite event(s) and register in that event by filling the form corresponding to that event. Its that easy :)",
    },
    {
      date: "1-6 May, 2021",
      event: "Registration",
      desc: "Pick your favourite event(s) and register in that event by filling the form corresponding to that event. Its that easy :)",
    },
  ];

  return (
    <section>
      <div className="bg-transparent text-white py-8">
        <div className="container mx-auto flex flex-col items-start md:flex-row my-12 md:my-24">
          <div className="flex flex-col w-full sticky md:top-36 lg:w-1/3 mt-2 md:mt-12 px-8 fade-in">
            <p className="ml-2 text-xl  text-yellow-300 uppercase tracking-loose">
              Milestone and Achivements
            </p>
            <p className="text-3xl md:text-4xl leading-normal md:leading-relaxed mb-2">
              {" "}
              <b> Beena Plastics Private Ltd. </b>{" "}
            </p>
            <p className="text-xs md:text-base text-gray-50 mb-4">
              <i>Wholesaler, Retailer, Service Provider</i>
            </p>
            <Link
              to="/products"
              className="bg-transparent mr-auto hover:bg-gray-900 text-yellow-300 rounded shadow hover:shadow-lg py-2 px-4 border border-gray-900 hover:border-yellow-300"
            >
              Explore Our Products <span aria-hidden="true">&rarr;</span>
            </Link>
          </div>
          <div className="ml-0 md:ml-12 lg:w-2/3 sticky">
            <div className="container mx-auto w-full h-full">
              <div className="relative wrap overflow-hidden p-10 h-full">
                <div className="border-2 border-yellow-300 absolute h-full inset-x-2/4 rounded-lg hover:border-dotted"></div>
                <div className="border-2 border-yellow-300 absolute h-full inset-x-2/4 rounded-lg hover:border-dotted"></div>
                {events.map((event, index) => {
                  if (index % 2) {
                    return (
                      <div key={index} className="card-left bg-g mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline">
                        <div className="order-1 w-2/4 mx-2"></div>
                        <div className=" border-2 bg-gray-900 border-black rounded-lg order-1 w-2/4 mx-2 px-4 py-4 text-right">
                          <p className="mb-3 text-base text-yellow-300">
                            {event.date}
                          </p>
                          <h4 className="mb-3 font-bold text-lg md:text-2xl">
                            {event.event}
                          </h4>
                          <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                            {event.desc}
                          </p>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div key={index} className="card-right bg-g mb-8 flex justify-between items-center w-full right-timeline">
                        <div className="order-1 w-2/4 mx-3"></div>
                        <div className=" border-2 bg-gray-900 border-black rounded-lg order-1  w-2/4 mx-3 px-4 py-4 text-left">
                          <p className="mb-3 text-base text-yellow-300">
                            {event.date}
                          </p>
                          <h4 className="mb-3 font-bold text-lg md:text-2xl">
                            {event.event}
                          </h4>
                          <p className="text-sm md:text-base leading-snug text-gray-50 text-opacity-100">
                            {event.desc}
                          </p>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              <img
                className="mx-auto -mt-36 md:-mt-36 "
                src="https://user-images.githubusercontent.com/54521023/116968861-ef21a000-acd2-11eb-95ac-a34b5b490265.png"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default History;
