import React from "react";
import pipe from "../resources/prdImgs/pipe1.png";
import grate from "../resources/prdImgs/grate1.png";
import tank from "../resources/prdImgs/vtank.png";
import bend from "../resources/prdImgs/bend1.png"
import { Link } from "react-router-dom";
import Atropos from "atropos/react";
import backimage from "../resources/prdImgs/bg13.jpg";

function LargeCTA() {
  const backgroundStyle = {
    backgroundImage:  `url(${backimage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  
  return (
    <div
      className="bg-transparent overflow-hidden relative lg:flex lg:items-center rounded-lg"
      style={backgroundStyle}
    >
      <div className="w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20 ">
        <h2 className="text-3xl font-extrabold text-yellow-300 sm:text-3xl">
          <span className="block">
            <span className="xs:block ">Find products according</span>
            <span> to your requirements!</span>
          </span>
        </h2>
        <p className="text-md mt-4 text-slate-50 ani-mid ">
          Wide range of quality gamut from FRP Pipe & Fitting, FRP Tanks, FRP
          Grates, FRP Hood, FRP Blower,  and much more.
        </p>
        <div className="lg:mt-0 lg:flex-shrink-0">
          <div className="mt-6 inline-flex rounded-md shadow">
            <button
              type="button"
              className="py-2 px-4  bg-transparent border-2 border-yellow-300 hover:bg-yellow-500 focus:ring-yrllow-500 focus:ring-offset-yellow-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            >
              <Link  to={"/Products"}>
                Explore Products
              </Link>
            </button>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-8 p-8 lg:p-24 ani-right">
        <div className="w-1/2">
          {window.screen.width > 650 ? (
            <Atropos>
              <Link to="/SingleProduct/1" >
                <img
                  loading="lazy"
                  
                  src={bend}
                  className="rounded-lg border border-neutral-800 bg-neutral-900/50 "
                  alt="Tree"
                />
              </Link>
            </Atropos>
          ) : (
            <Link to="/SingleProduct/1" >
              <img
                loading="lazy"
                
                src={bend}
                className="rounded-lg border border-neutral-800 bg-neutral-900/50 "
                alt="Tree"
              />
            </Link>
          )}
        </div>

        <div className="w-1/2">
          {window.screen.width > 650 ? (
            <Atropos>
              <Link to="/SingleProduct/3" >
                <img
                  loading="lazy"
                  
                  src={grate}
                  className="w-half mb-8 rounded-lg border border-neutral-800 bg-neutral-900/50"
                  alt="Tree"
                />
              </Link>
            </Atropos>
          ) : (
            <Link to="/SingleProduct/3" >
              <img
                loading="lazy"
                
                src={grate}
                className="w-half mb-8 rounded-lg border border-neutral-800 bg-neutral-900/50 "
                alt="Tree"
              />
            </Link>
          )}
          {window.screen.width > 650 ? (
            <Atropos>
              <Link to="/SingleProduct/8"  >
                <img
                  loading="lazy"
                 
                  src={tank}
                  className="w-half rounded-lg border border-neutral-800 bg-neutral-900/50"
                  alt="Tree"
                />
              </Link>
            </Atropos>
          ) : (
            <Link to="/SingleProduct/8" >
              <img
                loading="lazy"
                
                src={tank}
                className="w-half rounded-lg border border-neutral-800 bg-neutral-900/50"
                alt="Tree"
              />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default LargeCTA;
