import amns from "../resources/prdImgs/amns1.jpg"
import jsw from "../resources/prdImgs/jsw-image.png"
import adani from "../resources/prdImgs/adani.png"
import reliance from "../resources/prdImgs/reliance.png"
import colorshine from "../resources/prdImgs/colorshine.jpg"
import posco from "../resources/prdImgs/Posco12.png"
import evonith from "../resources/prdImgs/Evonith-steel.png"
import deepakfert from "../resources/prdImgs/deepakfert.png"
import chlrmet from "../resources/prdImgs/chloride-metals.png"


function Clients() {
  return (
    <>
      <div className="flex flex-col bg-transparent m-auto p-auto mt-20 ">
        <h1 className="flex py-5 lg:px-10 md:px-10 px-5 font-bold text-4xl text-yellow-300 logo-font logo-font tracking-tight antialiased">
          Our Clients
        </h1>
        <div className="flex overflow-x-scroll pb-10 hide-scroll-bar ">
          <div className="flex flex-nowrap lg:ml-40 md:ml-20 ml-10 scroller">
            <div className="inline-block px-3">
              <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-gray-800 hover:shadow-xl transition-shadow duration-300 ease-in-out">
                <img className="w-full h-full" src={amns} alt="amns logo"/>
              </div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-gray-800 hover:shadow-xl transition-shadow duration-300 ease-in-out"><img className="w-full h-full" src={jsw} alt="amns logo"/></div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-gray-800 hover:shadow-xl transition-shadow duration-300 ease-in-out"><img className="w-full h-full" src={adani} alt="amns logo"/></div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-gray-800 hover:shadow-xl transition-shadow duration-300 ease-in-out"><img className="w-full h-full" src={reliance} alt="amns logo"/></div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-gray-800 hover:shadow-xl transition-shadow duration-300 ease-in-out"><img className="w-full h-full" src={evonith} alt="amns logo"/></div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-gray-800 hover:shadow-xl transition-shadow duration-300 ease-in-out"><img className="w-full h-full" src={colorshine} alt="amns logo"/></div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-gray-800 hover:shadow-xl transition-shadow duration-300 ease-in-out"><img className="w-full h-full" src={posco} alt="amns logo"/></div>
            </div>
            <div className="inline-block px-3">
              <div className="w-64 h-64 max-w-xs overflow-hidden rounded-lg shadow-md bg-gray-800 hover:shadow-xl transition-shadow duration-300 ease-in-out"><img className="w-full h-full" src={deepakfert} alt="amns logo"/></div>
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
}

export default Clients;
